.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-size: none;
}

.form-control{
  // border: 0.5px solid #5582BC;
  border: 0.5px solid #979797;
  border-radius: 28px;
}

.toggle-disable {
  background-color: #D8D8D8;
  width: 56px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 1rem;
}
.toggle-active {
  background-color: $blue;
  width: 56px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  margin-left: 1rem;
}
.toggleInput {
  display: none;
}
.slider {
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 12px;
  margin: 4px;
}
.toggleInput:checked + .toggle {
  background-color: green;
}
.toggleInput:checked + .toggle > .slider {
  align-self: flex-end;
}

