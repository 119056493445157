.nav-tabs,
.nav-pills {
  .nav-link {
    color: $body-color;
    &:hover {
      color: $primary;
    }
  }
}

.tab-content {
  background: $white;
  &.tabcontent-border {
    border: 1px solid $border-color;
    border-top: 0px;
  }
}
// this is for custom pills
.nav-pills.custom-pills {
    .nav-link {
        border-radius: 0;
        opacity: 0.7;
        color: #CBCBCB; // for order details nav link
        &.active {
            // color: $themecolor;
            color: #133B64;
            opacity: 1;
            background-color: transparent;
            // border-bottom: 1px solid #133B64;
            position: relative;
        }
        // make half border bottom
        &.active::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 50%;
            border-bottom: 1px solid #133B64;
        }
    }
}
